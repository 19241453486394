.description {
  margin-bottom: 24px;
}

.description>textarea {
  width: 100%;
  min-height: 160px;
  overflow-y: auto !important;
}

.asterisk {
  composes: asterisk from global;
}
