@import '../../styles/customMediaQueries.css';

.desktopDiscountPrice {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  padding: 5px;
  border: 1px solid var(--matterColorAnti);
  border-radius: 4px;

  @media (--viewportSmall) {
    width: auto;
  }
}

.singleDiscountWidth {
  width: 100%;
}

.rightMargin {
  margin-right: 4px;
}

.desktopDiscountPriceLayout {
  display: flex;
  flex-direction: row;
  align-items: center;
}


.desktopDiscountPriceTag {
  /* Font */
  composes: h5 from global;
  font-weight: var(--fontWeightMedium);
  color: var(--matterColor);

  margin-top: 0;
  margin-bottom: 1px;
}

.desktopDiscountPriceValue {
  /* Font */
  composes: h3 from global;
  color: var(--marketplaceColor);
  margin-top: 0;
  margin-bottom: 1px;
}


.discountPriceDivider {
  composes: h6 from global;
  font-weight: var(--fontWeight);
  color: var(--matterColor);
  margin-top: 0;
  margin-bottom: 1px;
}


.desktopDiscountPerUnit {
  /* Font */
  composes: h6 from global;
  font-weight: var(--fontWeight);
  color: var(--matterColor);
  text-transform: lowercase;
  letter-spacing: 0;

  margin-top: 0;
  margin-bottom: 0;
}
