.root {
}

.password {
  composes: marketplaceModalPasswordMargins from global;
  margin-bottom: 24px;
}

.asterisk {
  composes: asterisk from global;
}
