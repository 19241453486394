@import '../../styles/customMediaQueries.css';

.root {
  margin: var(--LandingPage_sectionMarginTop) 24px 51px 24px;

  @media (--viewportMedium) {
    max-width: 100%;
    margin: var(--LandingPage_sectionMarginTopMedium) 24px 60px 24px;
  }

  @media (--viewportLarge) {
    max-width: 1128px;
    padding: 0 36px 0 36px;
    margin: var(--LandingPage_sectionMarginTopLarge) auto 93px auto;
  }

  @media (--viewportXLarge) {
    max-width: 1056px;
    padding: 0;
  }
}

.sectionHowWeCreateValuesTitle {
  composes: h1 from global;
  color: var(--matterColorDark);
  text-align: center;
  margin: 0 0 32px 0;

  @media (--viewportMedium) {
    margin: 0 0 47px 0;
  }
}

.higlightedByColor {
  color: var(--marketplaceColor);
}

.sectionHowWeCreateValuesConetnt {
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 40px;

  @media (--viewportMedium) {
    grid-template-columns: 1fr 1fr;
  }
}

.sectionHowWeCreateValuesConetntBox {
  width: 100%;
  background-color: var(--matterColorBrighter);
  padding: 28px 20px;
  border-radius: 40px;

  /* Layout */
  display: flex;
  flex-direction: column;
  align-items: center;
}

.iconWrapper {
  position: relative;
  width: 100%;
  height: 64px;
}

/* An image wrapper that defines the images aspect ratio */
.aspectWrapper {
  padding-bottom: calc(6 / 13 * 100%);
  /* 13:6 Aspect Ratio */

  @media (--viewportMedium) {
    padding-bottom: calc(2.5 / 13 * 100%);
    /* 3:2 Aspect Ratio */
  }
}

.easelyImage {
  /* Layout - image will take space defined by aspect ratio wrapper */
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.boxTitle {
  composes: 3 from global;
  color: var(--matterColorDark);
  margin: 12 0 0;
  text-align: center;
  text-transform: uppercase;
}

.easelySubtitle {
  composes: p from global;
  font-size: 14px;
  font-weight: 300;
  color: var(--matterColorDark);
  margin: 12 0 0;
  text-align: center;

  @media (--viewportMedium) {
    font-size: 14px;
    line-height: 18px;
    letter-spacing: 1px;
  }
}

.boxBtnWrapper {
  display: flex;
}

.link:hover {
  text-decoration: none;
}

.boxBtn {
  composes: button buttonFont buttonText buttonBorders buttonColors from global;
  min-height: 44px;
  text-transform: uppercase;
  font-weight: 300;
  padding: 10px 35px;
  margin: 10px 8px 0;
  background-color: var(--matterColorLight);
  color: var(--matterColorDark);
  border-radius: 40px;

  @media (--viewportLarge) {
    margin: 30px 20px 0;
  }
}

.boxBtn:hover {
  background-color: var(--marketplaceColor);
  color: var(--matterColorLight);
}
