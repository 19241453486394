@import '../../styles/customMediaQueries.css';

.root {
  /* Layout */
  display: flex;
  flex-direction: column;
  border-radius: 40px;
  padding: 80px 20px;
}

.cardTitle {
  composes: h3 from global;
  font-size: 20px;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: var(--marketplaceColor);
  text-align: center;
  margin:  20px 0;
}

.cardSubtitle {
  composes: p from global;
  font-size: 16px;
  font-weight: 300;
  line-height: 24px;
  letter-spacing: 1px;
  color: var(--matterColorDark);
  text-align: center;
  margin: 0;
  /* margin: 20px 0 28px 0;

  @media (--viewportMedium) {
    margin: 28px 0 38px 0;
  } */
}


.higlightedByColor {
  color: var(--marketplaceColor);
}

.iconWrapper {
  position: relative;
  width: 100%;
  height: 64px;
}

/* An image wrapper that defines the images aspect ratio */
.aspectWrapper {
  padding-bottom: calc(6 / 13 * 100%);
  /* 13:6 Aspect Ratio */

  @media (--viewportMedium) {
    padding-bottom: calc(2.5 / 13 * 100%);
    /* 3:2 Aspect Ratio */
  }
}

.easelyImage {
  /* Layout - image will take space defined by aspect ratio wrapper */
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  object-fit: contain;
}
