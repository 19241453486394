@import '../../styles/customMediaQueries.css';

.root {
  margin: var(--LandingPage_sectionMarginTop) 24px 51px 24px;

  @media (--viewportMedium) {
    max-width: 100%;
    margin: var(--LandingPage_sectionMarginTopMedium) 24px 60px 24px;
  }

  @media (--viewportLarge) {
    max-width: 1128px;
    padding: 0 36px 0 36px;
    margin: var(--LandingPage_sectionMarginTopLarge) auto 93px auto;
  }

  @media (--viewportXLarge) {
    max-width: 1056px;
    padding: 0;
  }
}

.sectionTopCategoriesTitle {
  composes: h1 from global;
  color: var(--matterColorDark);
  text-align: center;
  margin: 0 0 32px 0;

  @media (--viewportMedium) {
    margin: 0 0 47px 0;
  }
}

.highlightedByColor {
  color: var(--marketplaceColor);
}

.sectionTopCategoriesConetnt {
  display: flex;
  flex-wrap: wrap;
}

.categoryBtn {
  composes: button buttonFont buttonText buttonBorders buttonColors from global;
  width: auto;
  min-height: 34px;
  font-size: 14px;
  line-height: 22.4px;
  font-weight: 700;
  text-transform: uppercase;
  padding: 8px 26px;
  margin: 18px 12px 0 0;

  background-color: var(--marketplaceColor);
  color: var(--matterColorLight);
  border-radius: 40px;

  @media (--viewportMedium) {
    min-height: 44px;
    margin: 20px 20px 0 0;
  }
}
