@import '../../styles/customMediaQueries.css';

.root {
  margin: var(--LandingPage_sectionMarginTop) 24px 51px 24px;

  @media (--viewportMedium) {
    max-width: 100%;
    margin: var(--LandingPage_sectionMarginTopMedium) 24px 60px 24px;
  }

  @media (--viewportLarge) {
    max-width: 1128px;
    padding: 0 36px 0 36px;
    margin: var(--LandingPage_sectionMarginTopLarge) auto 93px auto;
  }

  @media (--viewportXLarge) {
    max-width: 1056px;
    padding: 0;
  }
}

.title {
  composes: h1 from global;
  color: var(--marketplaceColor);
  margin: 0 0 32px 0;
  text-align: center;

  @media (--viewportMedium) {
    text-align: center;
    margin: 0 0 47px 0;
  }
}

.highlightedByColor {
  color: var(--matterColorLight);
}

.subtitle {
  composes: h2 from global;
  color: var(--matterColorDark);
  margin: 20px 0 0;
  letter-spacing: 2.5px;
  text-transform: uppercase;
  text-align: center;

  @media (--viewportMedium) {
    text-align: left;
  }
}

.description {
  composes: p from global;
  font-size: 16px;
  line-height: 25.6px;
  font-weight: 300;
  letter-spacing: 0.5px;
  color: var(--matterColorThickDark);
  margin: 20px 0 0;
  text-align: center;

  @media (--viewportMedium) {
    text-align: left;
  }
}

.addProjectBtn {
  composes: button buttonFont buttonText buttonBorders buttonColors from global;
  max-width: 195px;
  min-height: 44px;
  /* text-transform: uppercase; */
  font-size: 16px;
  line-height: 25.6px;
  font-weight: 700;
  padding: 10px 30px;
  margin: 30px auto 0;
  background-color: var(--marketplaceColor);
  color: var(--matterColorLight);
  border-radius: 40px;

  @media (--viewportMedium) {
    margin: 30px 0 0;
  }
}

.addProjectBtn:hover {
  background-color: var(--matterColorLight);
  color: var(--marketplaceColor);
}

.logoContainer {
  display: grid;
  grid-template-columns: 1fr;

  @media (--viewportMedium) {
    grid-template-columns: repeat(3, 1fr);
  }
}

.imageWrapper {
  position: relative;
  width: 100%;
}

/* An image wrapper that defines the images aspect ratio */
.aspectWrapper {
  padding-bottom: calc(6 / 13 * 100%);
  /* 13:6 Aspect Ratio */

  @media (--viewportMedium) {
    padding-bottom: calc(2 / 3 * 100%);
    /* 3:2 Aspect Ratio */
  }
}

.imageMargin {
  position: absolute;
  inset: 0px;
  margin: 26px;
}

.tryEaselyImage {
  /* Layout - image will take space defined by aspect ratio wrapper */
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  object-fit: contain;
}
