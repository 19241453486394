@import '../../../styles/customMediaQueries.css';

.flickingPanel {
  background-color: var(--matterColorLight);
  width: 85%;
  margin: 0 4px;
  padding: 24px 12px;
  border-radius: 40px;

  /* Layout */
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  cursor: grab;

  transition: background-color 0.3ms linear;

  @media (--viewportMedium) {
    width: 32.70%;
    padding: 30px;
  }
}

.activePanel {
  background-color: var(--matterColorActivePanel);
  transition: background-color 300ms ease-in-out;
}

/* Firefox doesn't support image aspect ratio inside flexbox */
.aspectWrapper {
  width: 90%;
  margin: 0 auto;
  position: relative;
  /* 3:2 Aspect Ratio */
  padding-bottom: 66.6667%;
  /* Loading BG color */
  background: var(--matterColorNegative);

  border-radius: 40px;
}

.rootForImage {
  /* Layout - image will take space defined by aspect ratio wrapper */
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  border-radius: 40px;
  padding: 0;
  margin: 0;
}

.title {
  composes: h3 from global;
  font-size: 20px;
  color: var(--matterColor);
  font-weight: var(--fontWeightBold);
  letter-spacing: 1px;
  line-height: 24px;
  text-transform: uppercase;
  text-align: center;
  padding: 0 10px;
  margin: 20px 0 23px;
}

.description {
  font-size: 16px;
  line-height: 25.6px;
  font-weight: 300;
  color: var(--matterColorDark);
  padding: 0 10px;
  text-align: center;
}

.activePanel .title,
.activePanel .description {
  color: var(--matterColorLight);
  transition: color 300ms ease-in-out;
}

.showLesMoreBtn {
  color: var(--marketplaceColor);
  cursor: pointer;
}

.sdgsWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  padding: 0 10px;
  margin: 0 0 20px;
}

.sdgsImages {
  margin: 4px;
}

.selectBtnWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 0 20px;
}
.btn {
  width: 50%;
}
.btnSlected {
  background-color: var(--marketplaceColorDark);
}

.arrow {
  display: none;

  @media (--viewportMedium) {
    width: 40px;
    height: 40px;
    background-color: var(--matterColorLight);
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: var(--boxShadow);
  }
}

.arrow::before,
.arrow::after {
  width: 0;
  height: 0;
}

.arrow>svg {
  width: 20px;
  height: 20px;
}

.arrow>svg path {
  fill: var(--marketplaceColor);
}
