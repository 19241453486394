@import '../../styles/customMediaQueries.css';

.root {
  margin: var(--LandingPage_sectionMarginTop) 0 51px 0;

  @media (--viewportMedium) {
    max-width: 100%;
    margin: var(--LandingPage_sectionMarginTopMedium) 24px 60px 24px;
  }

  @media (--viewportLarge) {
    max-width: 1128px;
    padding: 0 36px 0 36px;
    margin: var(--LandingPage_sectionMarginTopLarge) auto 93px auto;
  }

  @media (--viewportXLarge) {
    max-width: 1056px;
    padding: 0;
  }
}

.title {
  composes: h1 from global;
  color: var(--matterColorDark);
  margin: 0 0 32px 0;
  text-align: center;

  @media (--viewportMedium) {
    margin: 0 0 47px 0;
  }
}

.highlightedByColor {
  color: var(--marketplaceColor);
}

.flickingPanel {
  width: 85%;
  margin: 0 4px;
  background-color: var(--matterColorBrighter);
  border-radius: 40px;

  /* Layout */
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  cursor: grab;

  @media (--viewportMedium) {
    width: 30%;
  }

  @media (--viewportLarge) {
    width: 25%;
  }
}

.arrow {
  display: none;

  @media (--viewportMedium) {
    width: 40px;
    height: 40px;
    background-color: var(--matterColorLight);
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: var(--boxShadow);
  }
}

.arrow::before,
.arrow::after {
  width: 0;
  height: 0;
}

.arrow>svg {
  width: 20px;
  height: 20px;
}

.arrow > svg path {
  fill: var(--marketplaceColor);
}
