@import '../../styles/customMediaQueries.css';

.root {
  margin: var(--LandingPage_sectionMarginTop) 24px 51px 24px;

  /* Layout */
  display: grid;
  grid-template-columns: 1fr;

  @media (--viewportMedium) {
    max-width: 100%;
    margin: var(--LandingPage_sectionMarginTopMedium) 24px 60px 24px;

    display: grid;
    grid-template-columns: 2fr 1fr;
  }

  @media (--viewportLarge) {
    max-width: 1128px;
    padding: 0 36px 0 36px;
    margin: var(--LandingPage_sectionMarginTopLarge) auto 93px auto;
  }

  @media (--viewportXLarge) {
    max-width: 1056px;
    padding: 0;
  }
}

.titleWrapper {
  display: none;

  @media (--viewportMedium) {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.title {
  composes: h1 from global;
  max-width: 288px;
  color: var(--matterColorLight);
  text-align: left;
  margin: 0 auto;
}

.mobileTitle {
  composes: h1 from global;
  /* max-width: 288px; */
  color: var(--matterColorLight);
  text-align: center;
  margin: 0 0 34px 0;

  @media (--viewportMedium) {
    display: none;
  }
}

.higlightedByColor {
  color: var(--marketplaceColor);
}
