@import '../../styles/customMediaQueries.css';

/* Animation for the hero elements */

@keyframes animationTitle {
  0% {
    visibility: hidden;
    opacity: 0;
    top: -20px;
  }
  100% {
    visibility: visible;
    opacity: 1;
    top: 0;
  }
}

/* Shared animation rules */
.animation {
  animation-name: animationTitle;
  animation-duration: 0.5s;
  animation-timing-function: ease-out;
  -webkit-animation-fill-mode: forwards;
  animation-delay: 3s;

  visibility: hidden;
  opacity: 1;
  position: relative;
}

.root {
  /*composes: defaultBackgroundImage from global;*/
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;

  /* Text positioning is done with paddings */
}

.heroContent {
  width: 100%;
  height: 100vh;
  margin: 0 24px 0 24px;

  @media (--viewportMedium) {
    margin: 0 7.5vw 0 7.5vw;
  }

  /* Special viewport for adjusting the heroContent's placement */

  @media only screen and (min-width: 1025px) and (max-width: 1366px) {
    padding: 0 36px 0 36px;
  }

  @media only screen and (min-width: 1367px) {
    margin: 0 auto;
    max-width: calc(1052px + 36px + 36px);
    width: 100%;
    padding: 0 36px 0 36px;
  }


  @media (max-height: 450px) {
    margin-bottom: 110px;
  }
}

.heroMainTitle {
  max-width: 200px;
  margin-top: 300px;
  margin-bottom: 14px;
  composes: animation;

  @media (--viewportMedium) {
    max-width: 300px;
    margin-bottom: 23px;
  }
}

.heroMainTitleFEDelay {
  animation-delay: 0s;
}

.heroMainTitle > img {
  width: 100%;
  height: 100%;
}

.heroSubTitle {
  composes: h2 from global;
  color: var(--matterColorLight);
  margin: 0 0 32px 0;
  composes: animation;

  @media (--viewportMedium) {
    font-size: 30px;
    line-height: 42px;
    letter-spacing: 1.5px;
    max-width: var(--SectionHero_desktopTitleMaxWidth);
    margin: 0 0 47px 0;
  }
}
.heroSubTitleFEDelay {
  animation-delay: 0.15s;
}

.spanColor {
  color: var(--marketplaceColor);
}

.heroButton {
  composes: button buttonFont buttonText buttonBorders buttonColors from global;
  composes: animation;
  background-color: var(--marketplaceColor);
  color: var(--matterColorLight);
  border-radius: 30px;
  max-width: 260px;
  max-height: 10px !important;
  text-align: center;

  @media (--viewportMedium) {
    display: block;
  }

  @media (--viewportLarge) {
    margin: 0px;
  }

}

.heroButton:hover {
  background-color: var(--matterColorLight);
  color: var(--matterColorDark);
}

.heroButtonFEDelay {
  animation-delay: 0.3s;
}

.landingVideo {
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  width: 100%;
  height: 100%;
  /*@media screen and (max-width: 768px) and (min-height: 376px){*/
  /*    height: 75%;*/
  /*    padding-bottom: 0px;*/
  /*}*/
}

@media (min-aspect-ratio: 16/9) {
  .landingVideo{

    width: 100%;
    height: auto;
  }
}
@media (max-aspect-ratio: 16/9) {
  .landingVideo {

    width:100%;
    height: 100%;

    padding:0
  }
}

video{
  object-fit:cover
}