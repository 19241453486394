@import '../../styles/customMediaQueries.css';

.root {
  margin: var(--LandingPage_sectionMarginTop) 24px 51px 24px;

  @media (--viewportMedium) {
    max-width: 100%;
    margin: var(--LandingPage_sectionMarginTopMedium) 24px 60px 24px;
  }

  @media (--viewportLarge) {
    max-width: 1128px;
    padding: 0 36px 0 36px;
    margin: var(--LandingPage_sectionMarginTopLarge) auto 93px auto;
  }

  @media (--viewportXLarge) {
    max-width: 1056px;
    padding: 0;
  }
}

.sectionGlobalChallengesTitle {
  composes: h1 from global;
  color: var(--matterColorDark);
  text-align: center;
  margin: 0;

  @media (--viewportMedium) {
    text-align: left;
  }
}

.highlightedByColor {
  color: var(--marketplaceColor);
}

.sectionGlobalChallengesSubtitle {
  composes: h3 from global;
  margin-top: 0;
  text-align: center;

  @media (--viewportMedium) {
    text-align: left;
  }
}

.sectionGlobalChallengesContent {
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 50px;
  margin-top: 50px;

  @media (--viewportMedium) {
    grid-template-columns: 1fr 1fr;
    margin-top: 60px;
  }
}

.sectionGlobalChallengesInfo {
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 30px;

  @media (--viewportMedium) {
    grid-template-columns: 1fr 2fr;
  }
}

.imageWrapper {
  position: relative;
  width: 100%;
}

.iconWrapper {
  position: relative;
  width: 100%;
  height: 64px;
}

/* An image wrapper that defines the images aspect ratio */
.aspectWrapper {
  padding-bottom: calc(6 / 13 * 100%);
  /* 13:6 Aspect Ratio */

  @media (--viewportMedium) {
    padding-bottom: calc(2 / 3 * 100%);
    /* 3:2 Aspect Ratio */
  }
}

.easelyImage {
  /* Layout - image will take space defined by aspect ratio wrapper */
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.subtitle {
  composes: p from global;
  font-size: 14px;
  font-weight: 300;
  color: var(--matterColorDark);
  margin: 12 0 0;
  text-align: center;

  @media (--viewportMedium) {
    font-size: 14px;
    line-height: 18px;
    letter-spacing: 1px;
    text-align: left;
  }
}

.highlightedByBold {
  font-weight: 700;
}
