@import '../../../styles/customMediaQueries.css';

.genderLabel {
  display: block;

  font-weight: var(--fontWeightSemiBold);
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0;

  margin-top: 0;
  margin-bottom: 0;

  @media (--viewportMedium) {
    font-weight: var(--fontWeightSemiBold);
    line-height: 16px;
    margin-top: 0;
    margin-bottom: 0;
    padding-top: 6px;
    padding-bottom: 2px;
  }
}

.genderInput {
  margin-bottom: 24px;
}
