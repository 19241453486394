@import '../../styles/customMediaQueries.css';

.root {
  /* margin: var(--LandingPage_sectionMarginTop) 24px 51px 24px; */
  margin: 80px 24px 51px 24px;

  @media (--viewportMedium) {
    max-width: 100%;
    margin: var(--LandingPage_sectionMarginTopMedium) 24px 60px 24px;
  }

  @media (--viewportLarge) {
    max-width: 1128px;
    padding: 0 36px 0 36px;
    margin: var(--LandingPage_sectionMarginTopLarge) auto 93px auto;
  }

  @media (--viewportXLarge) {
    max-width: 1056px;
    padding: 0;
  }
}

.sectionGaqTitle {
  composes: h1 from global;
  color: var(--matterColorLight);
  text-align: center;
  margin: 0 0 18px 0;
}

.sectionGaqSubtitle {
  composes: p from global;
  color: var(--matterColorLight);
  text-align: center;
  font-style: italic;
  margin: 0 0 32px 0;

  @media (--viewportMedium) {
    margin: 0 0 47px 0;
  }
}

.questionWrapper {
  width: 100%;
  background-color: var(--matterColorLightDark);
  padding: 12px 24px;
  margin-top: 10px;
  border-radius: 14px;
  transition: background-color 0.3ms ease-in-out;

  /* Layout */
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media (--viewportMedium) {
    padding: 25px 30px;
    border-radius: 27px;
  }
}

.questionWrapper:hover {
  background-color: var(--matterColorHover);
  cursor: pointer;
}

.question {
  composes: h4 from global;
  max-width: 85%;
  font-size: 14px;
  font-weight: 500;
  line-height: 21px;
  color: var(--matterColorLight);
  margin: 0;

  @media (--viewportMedium) {
    font-size: 18px;
    font-weight: 500;
    line-height: 28px;
  }
}

.icon {
  width: 16px;
  height: 16px;
  color: var(--matterColorLight);

  @media (--viewportMedium) {
    width: 25px;
    height: 25px;
  }
}

.answerWrapper {
  padding: 12px 24px;

  @media (--viewportMedium) {
    padding: 20px 30px;
  }
}

.answer {
  composes: p from global;
  font-size: 14px;
  font-weight: 300;
  line-height: 25.6px;
  color: var(--matterColorLight);
  margin: 20px 0;

  @media (--viewportMedium) {
    font-size: 16px;
  }
}
