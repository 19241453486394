@import '../../styles/customMediaQueries.css';

.locationAddress,
.building {
  flex-shrink: 0;
  margin-bottom: 24px;

  @media (--viewportMedium) {
    margin-bottom: 32px;
  }
}

.locationAutocompleteInput {
  flex-grow: 1;
  height: 36px;
  line-height: unset;
  padding-left: 0;
  margin: 0;
  border-bottom: 2px solid var(--attentionColor);

  &:hover,
  &:focus {
    border-bottom-color: var(--matterColorDark);
    outline: none;
  }

  /* Safari bugfix: without this Safari will print placeholder to a wrong place */
  &::-webkit-input-placeholder {
    line-height: normal;
  }

  @media (--viewportMedium) {
    height: 40px;
  }
}

.locationAutocompleteInputIcon {
  display: none;
}

.predictionsRoot {
  position: absolute;
  width: 100%;
  padding-bottom: var(--locationAutocompleteBottomPadding);
  top: 36px;
  left: 0;
  background-color: var(--marketplaceColor);
  border-bottom-left-radius: var(--borderRadius);
  border-bottom-right-radius: var(--borderRadius);
  box-shadow: var(--boxShadowPopup);
  z-index: calc(var(--zIndexPopup) + 1);

  @media (--viewportMedium) {
    top: 40px;
  }
}

.validLocation {
  border-bottom-color: var(--successColor);
}

.locationAddress,
.building {
  flex-shrink: 0;
  margin-bottom: 24px;

  @media (--viewportMedium) {
    margin-bottom: 32px;
  }
}

.isDeliverable {
  flex-shrink: 0;
  width: 100%;
  margin-bottom: 24px;
}

.manageavailability {
  min-height: 480px;

  @media (----viewportSmall) {
    min-height: 800px;
  }
}

/* Images */
.imagesField {
  flex-shrink: 0;
  margin: 0;
}

.thumbnail {
  margin: 24px 0 0 0;

  @media (--viewportLarge) {
    width: calc(50% - 12px);
    margin: 0 24px 24px 0;

    &:nth-child(even) {
      margin: 0 0 24px 0;
    }
  }
}

.addImageWrapper {
  float: left;
  position: relative;
  width: 100%;
  margin: 24px 0 0 0;
  overflow: hidden;

  &::after {
    content: '.';
    visibility: hidden;
    display: block;
    height: 0;
    clear: both;
  }

  @media (--viewportLarge) {
    width: calc(50% - 12px);
    margin: 0 0 24px 0;
  }
}

/* Firefox doesn't support image aspect ratio inside flexbox */
.aspectRatioWrapper {
  padding-bottom: calc(100% * (2 / 3));
}

.addImage {
  /* Layout */
  /* Maintain aspect ratio */
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 0;

  /* Colors */
  background-color: var(--matterColorLight);

  @media (--viewportLarge) {
    background-color: var(--matterColorBright);
  }

  border-style: dashed;
  border-color: var(--matterColorNegative);
  border-width: 2px;
  border-radius: 2px;

  /* Behaviour */
  cursor: pointer;
  transition: var(--transitionStyleButton);

  &:hover {
    border-color: var(--matterColorAnti);
  }
}

.chooseImageText {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.chooseImage {
  composes: h4 from global;
  color: var(--matterColor);
  font-weight: var(--fontWeightSemiBold);

  margin-top: 0;
  margin-bottom: 0;

  @media (--viewportLarge) {
    font-weight: var(--fontWeightSemiBold);
    margin-top: 0;
    margin-bottom: 0;
  }
}

.imageTypes {
  composes: marketplaceTinyFontStyles from global;
  color: var(--matterColorAnti);

  margin-top: 0;
  margin-bottom: 0;

  @media (--viewportLarge) {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.addImageInput {
  display: none;
}

.imageRequiredWrapper {
  width: 100%;
  clear: both;
}

.asterisk {
  composes: asterisk from global;
}

.titleContainer button {
  max-width: 110px;
  float: right;
  top: -2px;
  position: relative;
  font-size: 13px;
  min-height: 30px;
  font-weight: 700;
}
.spinner {
  stroke: var(--matterColorLight);
  width: 20px;
  height: 20px;
  stroke-width: 2px;
}
