.root {
  display: flex;
  align-items: center;
}

.switcherLabel {
  /* margin-right: 12px; */
  composes: h4 from global;
  line-height: 20px;
  margin-top: 10px;
  margin-bottom: 11px;
  margin-left: 0px;
  margin-right: 8px;
}
