@import '../../styles/customMediaQueries.css';

.root {
  margin: var(--LandingPage_sectionMarginTop) 24px 51px 24px;

  @media (--viewportMedium) {
    max-width: 100%;
    margin: var(--LandingPage_sectionMarginTopMedium) 24px 60px 24px;
  }

  @media (--viewportLarge) {
    max-width: 1128px;
    padding: 0 36px 0 36px;
    margin: var(--LandingPage_sectionMarginTopLarge) auto 93px auto;
  }

  @media (--viewportXLarge) {
    max-width: 1056px;
    padding: 0;
  }
}

.title {
  composes: h1 from global;
  color: var(--matterColorDark);
  margin: 0 0 32px 0;
  text-align: center;

  @media (--viewportMedium) {
    margin: 0 0 47px 0;
  }
}

.highlightedByColor {
  color: var(--marketplaceColor);
}

.contentWrapper {
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 30px;

  @media (--viewportLarge) {
    grid-template-columns: 1fr 1fr;
    grid-gap: 50px;
  }
}

.contentItem {
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 20px;

  @media (--viewportMedium) {
    grid-template-columns: 1fr 4fr;
    grid-gap: 30px;
  }
}

.imageWrapper {
  position: relative;
  width: 130px;
  height: 130px;
  margin: 0 auto;

  @media (--viewportMedium) {
    margin: 0;
  }
}

/* An image wrapper that defines the images aspect ratio */
.aspectWrapper {
  padding-bottom: calc(6 / 13 * 100%);
  /* 13:6 Aspect Ratio */

  @media (--viewportMedium) {
    padding-bottom: calc(2 / 3 * 100%);
    /* 3:2 Aspect Ratio */
  }
}

.easelyImage {
  /* Layout - image will take space defined by aspect ratio wrapper */
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 50%;
}

.description {
  composes: p from global;
  font-size: 16px;
  line-height: 22px;
  font-weight: 400;
  letter-spacing: 0.5px;
  color: var(--matterColorDark);
  margin: 0;
  text-align: center;

  @media (--viewportMedium) {
    text-align: left;
  }
}

.name {
  composes: h2 from global;
  color: var(--matterColorDark);
  margin: 20px 0 0;
  letter-spacing: 2.5px;
  text-transform: uppercase;
  text-align: center;

  @media (--viewportMedium) {
    text-align: left;
  }
}

.tag {
  composes: h3 from global;
  color: var(--matterColor);
  font-weight: 400;
  margin: 5px 0 10px;
  text-transform: uppercase;
  text-align: center;

  @media (--viewportMedium) {
    text-align: left;
  }
}
