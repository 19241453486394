@import '../../styles/customMediaQueries.css';

.root {
  /* width: 100%; */
  height: var(--topbarHeightDesktop);
}

.inputClass {
  width: 100%;
}

.desktopInputRoot {
  height: var(--topbarHeightDesktop);
  position: relative;
  display: flex;
}

.icon {
  width: 24px;
  display: flex;
  align-items: center;
  margin-left: 10px;
}

.iconSvg {
  margin: auto;
}

.iconSvgGroup {
  stroke: var(--marketplaceColor);
  stroke-width: 2.5px;

  @media (--viewportMedium) {
    stroke-width: 2px;
  }
}

.desktopIcon {
  height: var(--topbarHeightDesktop);
  border: none;
  padding-top: 3px;

  display: flex;
  width: 24px;
  align-self: stretch;
  background-color: var(--matterColorDark);
  margin-left: 10px;
}

.desktopInput {
  composes: h4 from global;
  flex-grow: 1;
  /* Font */
  height: var(--topbarHeightDesktop);
  line-height: unset;
  border: none;
  padding-top: 0;
  padding-bottom: 0;
  padding-left: 10px;

  /* Search text gets ellipsis if it's too wide */
  text-overflow: ellipsis;
  overflow-x: hidden;

  &:hover,
  &:focus {
    border-bottom-color: var(--marketplaceColor);
    outline: none;
  }

  &:hover::placeholder,
  &:focus::placeholder {
    color: var(--matterColor);
  }

  /* Placeholder text might be too wide */
  &::placeholder {
    text-overflow: ellipsis;
    overflow-x: hidden;
    font-weight: var(--fontWeightMedium);
    transition: var(--transitionStyleButton);
  }

  margin-top: 0;
  margin-bottom: 0;

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
    font-weight: var(--fontWeightSemiBold);
  }
}

.mobileIcon {
  margin-left: 24px;
  padding: 11px 0 17px 0;

  /* Borders */
  border-bottom-width: var(--borderRadiusMobileSearch);

  display: flex;
  width: 24px;
  align-self: stretch;
  background-color: var(--matterColorLight);
}

.mobileInput {
  flex-grow: 1;

  /* Font */
  composes: h1 from global;

  /* Layout */
  margin: 0 24px 0 0;
  padding: 4px 13px 10px 13px;
  height: var(--TopbarSearchForm_inputHeight);
  line-height: unset;

  /* Borders */
  border-bottom-width: var(--borderRadiusMobileSearch);

  /* Safari bugfix: without this Safari will print placeholder to a wrong place */
  &::-webkit-input-placeholder {
    line-height: normal;
  }

  &:hover,
  &:focus {
    border-bottom-color: var(--marketplaceColor);
    outline: none;
  }
}
