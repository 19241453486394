@import '../../styles/customMediaQueries.css';

/* Animation for the bubbles elements */
@keyframes customAnimationIn {
  from {
    transform-origin: center center;
    transform: scale(0);
    opacity: 0;
  }
  to {
    transform-origin: center center;
    transform: scale(1);
    opacity: 1;
  }
}

@keyframes customAnimationOut {
  from {
    transform-origin: center center;
    transform: scale(1);
    opacity: 1;
  }
  to {
    transform-origin: center center;
    transform: scale(0);
    opacity: 0;
  }
}

.root {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 20px;

  @media (--viewportLarge) {
    min-height: 611px;
    grid-template-columns: 2fr 1fr;
    grid-gap: 40px;
  }
}

.sectionMakeImpactTitle {
  composes: h1 from global;
  color: var(--matterColorLight);
  margin: 0 0 32px 0;
  text-align: center;

  @media (--viewportMedium) {
    margin: 0 0 47px 0;
    text-align: left;
  }
}

.highlightedByColor {
  color: var(--marketplaceColor);
}

.listWrapper {
  max-width: 440px;
  margin-top: 20px;
}

.listItem {
  /* Layout */
  display: flex;
  align-items: flex-start;
  margin-bottom: 12px;
  @media (--viewportMedium) {
    margin-bottom: 18px;
  }
}

.itemInfo {
  composes: p from global;
  font-size: 16px;
  font-weight: 300;
  color: var(--matterColorLight);
  margin: 0;

  @media (--viewportMedium) {
    font-size: 18px;
  }
}

.highlightedByBold {
  font-weight: 700;
  letter-spacing: 1px;
}

.iconWrapper {
  margin-right: 12px;
}

.checkMarkIcon {
  width: 18px;
  height: 18px;
  vertical-align: middle;
  line-height: 0.9;
}

.checkMarkIcon > path {
  fill: var(--marketplaceColor);
}

.discoverProjectButton {
  composes: button buttonFont buttonText buttonBorders buttonColors from global;
  font-size: 14px;
  font-weight: 400;
  line-height: 22.4px;
  letter-spacing: 2px;
  border-radius: 30px;
  text-transform: uppercase;
  margin-top: 40px;

  @media (--viewportMedium) {
    display: block;
    width: 380px;
  }
}

.discoverProjectButton:hover {
  background-color: var(--matterColorLight);
  color: var(--matterColorDark);
}

.makeImpactImage {
  display: none;

  @media (--viewportMedium) {
    display: block;
    position: absolute;
    top: 22%;
    right: 0;
    width: 40%;
    height: 50%;
    /* Gradient direction and overlaying the black color on top of the image for better readability */
    background: linear-gradient(-45deg, rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.6)),
      url('./make-impact.png');

    background-repeat: no-repeat;

    /* Add loading color for the div */
    background-color: var(--matterColor);

    /* Cover the whole screen with the background image */
    background-size: cover;

    /* Align the image within the container */
    background-position: center center;
    border-radius: 50px;
  }

  @media (--viewportLarge) {
    top: 10%;
    width: 35%;
    height: 60%;
  }
}

.bubbleIn1 {
  /* Shape */
  width: 50px;
  height: 50px;
  color: #ffffff;
  background-color: #e1199e;
  border-radius: 50%;
  box-shadow: 2px 0px 0px 0px rgb(0 0 0 / 0%);

  /* Position */
  position: absolute;
  top: -10px;
  left: -10px;

  /* Animation */
  will-change: transform, opacity;
  animation-name: customAnimationIn;
  animation-duration: 1000ms;
  animation-fill-mode: both;

  @media (--viewportMedium) {
    width: 100px;
    height: 100px;

    top: 0;
    left: -30px;
  }

  @media (--viewportLarge) {
    width: 146px;
    height: 146px;

    top: 0;
    left: -60px;
  }
}

.bubbleOut1 {
  /* Shape */
  width: 50px;
  height: 50px;
  color: #ffffff;
  background-color: #e1199e;
  border-radius: 50%;
  box-shadow: 2px 0px 0px 0px rgb(0 0 0 / 0%);

  /* Position */
  position: absolute;
  top: -10px;
  left: -10px;

  /* Animation */
  will-change: transform, opacity;
  animation-name: customAnimationOut;
  animation-duration: 1000ms;
  animation-fill-mode: both;

  @media (--viewportMedium) {
    width: 100px;
    height: 100px;
    top: 0;
    left: -30px;
  }

  @media (--viewportLarge) {
    width: 146px;
    height: 146px;

    top: 0;
    left: -60px;
  }
}

.bubbleIn2 {
  /* Shape */
  width: 20px;
  height: 20px;
  background-color: #e1199e;
  border-radius: 50%;
  box-shadow: 2px 0px 0px 0px rgb(0 0 0 / 0%);

  /* Position */
  position: absolute;
  top: 60%;
  left: 32%;

  /* Animation */
  will-change: transform, opacity;
  animation-name: customAnimationIn;
  animation-duration: 1000ms;
  animation-fill-mode: both;

  @media (--viewportMedium) {
    width: 53px;
    height: 53px;
  }
}

.bubbleOut2 {
  /* Shape */
  width: 20px;
  height: 20px;
  background-color: #e1199e;
  border-radius: 50%;
  box-shadow: 2px 0px 0px 0px rgb(0 0 0 / 0%);

  /* Position */
  position: absolute;
  top: 60%;
  left: 32%;

  /* Animation */
  will-change: transform, opacity;
  animation-name: customAnimationOut;
  animation-duration: 1000ms;
  animation-fill-mode: both;

  @media (--viewportMedium) {
    width: 53px;
    height: 53px;
  }
}

.bubbleIn3 {
  /* Shape */
  width: 30px;
  height: 30px;
  color: #ffffff;
  background-color: #e1199e;
  border-radius: 50%;
  box-shadow: 2px 0px 0px 0px rgb(0 0 0 / 0%);

  /* Position */
  position: absolute;
  top: 5%;
  right: -10px;

  /* Animation */
  will-change: transform, opacity;
  animation-name: customAnimationIn;
  animation-duration: 1000ms;
  animation-fill-mode: both;

  @media (--viewportMedium) {
    width: 70px;
    height: 70px;

    top: 75%;
    left: -50px;
  }

  @media (--viewportLarge) {
    width: 79px;
    height: 79px;

    top: 75%;
    left: -79px;
  }
}

.bubbleOut3 {
  /* Shape */
  width: 30px;
  height: 30px;
  color: #ffffff;
  background-color: #e1199e;
  border-radius: 50%;
  box-shadow: 2px 0px 0px 0px rgb(0 0 0 / 0%);

  /* Position */
  position: absolute;
  top: 5%;
  right: -10px;

  /* Animation */
  will-change: transform, opacity;
  animation-name: customAnimationOut;
  animation-duration: 1000ms;
  animation-fill-mode: both;

  @media (--viewportMedium) {
    width: 70px;
    height: 70px;

    top: 75%;
    left: -50px;
  }

  @media (--viewportLarge) {
    width: 79px;
    height: 79px;

    top: 75%;
    left: -79px;
  }
}

.imageWrapper {
  position: relative;
  width: 100%;

  @media (--viewportMedium) {
    display: none;
  }
}

/* An image wrapper that defines the images aspect ratio */
.aspectWrapper {
  padding-bottom: calc(6 / 13 * 100%);
  /* 13:6 Aspect Ratio */

  @media (--viewportMedium) {
    padding-bottom: calc(2 / 3 * 100%);
    /* 3:2 Aspect Ratio */
  }
}

.easelyImage {
  /* Layout - image will take space defined by aspect ratio wrapper */
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.link {
  text-decoration: none !important;
}
