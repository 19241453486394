@import '../../../styles/customMediaQueries.css';

.root {
  flex-shrink: 0;
  width: 100%;
  position: relative;
  margin-bottom: 24px;
}



.uploadVideoWrapper {
  position: relative;
  width: 100%;
  /* margin: 24px 0 0 0; */
  overflow: hidden;

  /* &::after {
    content: '.';
    visibility: hidden;
    display: block;
    height: 0;
    clear: both;
  } */
}

/* Firefox doesn't support image aspect ratio inside flexbox */
.aspectRatioWrapper {
  /* padding-bottom: calc(100% * (2 / 3)); */
  width: 100%;
  height: 40px;

}

.uploadVideo {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-top: 0;

  /* Colors */
  /* background-color: var(--matterColorLight); */
  color: var(--matterColorAnti);

  /* @media (--viewportLarge) {
    background-color: var(--matterColorBright);
  } */

  border-bottom-style: solid;
  /* border-color: var(--matterColorNegative); */
  border-bottom-color: var(--successColor);
  border-width: 2px;
  /* border-radius: 2px; */

  /* Behaviour */
  cursor: pointer;
  transition: var(--transitionStyleButton);

}

.uploadVideo:hover {
  border-color: var(--matterColor);
}

.inputSuccess {
  border-bottom-color: var(--successColor);
}

.inputDisabled {
  border-bottom-color: var(--matterColorAnti);
  cursor: not-allowed;
}

.inputError {
  border-bottom-color: var(--failColor);
}

.chooseImageText {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.chooseImage {
  composes: h4 from global;
  color: var(--matterColor);
  font-weight: var(--fontWeightSemiBold);

  margin-top: 0;
  margin-bottom: 0;

  @media (--viewportLarge) {
    font-weight: var(--fontWeightSemiBold);
    margin-top: 0;
    margin-bottom: 0;
  }
}

.imageTypes {
  composes: marketplaceTinyFontStyles from global;
  color: var(--matterColorAnti);

  margin-top: 0;
  margin-bottom: 0;

  @media (--viewportLarge) {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.uploadVideoInput {
  display: none;
}

.playerWrapper {
  background: var(--matterColorNegative);
  position: relative;
  padding-bottom: 66.6667%;
  /* Player ratio: 100 / (1280 / 720) */
}

.player {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  border-radius: var(--borderRadius);
}

.imageRequiredWrapper {
  width: 100%;
  clear: both;
}

.errorMsg {
  composes: h4 from global;
  color: var(--failColor);
  margin: 4px 0;
}

.tip {
  composes: h5 from global;

  flex-shrink: 0;
  color: var(--matterColorAnti);
  margin-top: 24px;
  margin-bottom: 60px;

  @media (--viewportLarge) {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.error {
  color: var(--failColor);
}
