@import '../../styles/customMediaQueries.css';

.root {
  /* Layout */
  display: flex;
  flex-direction: column;
  border-radius: 40px;
}

.root:hover {
  text-decoration: none;
}

.threeToTwoWrapper {
  /* Layout */
  display: block;
  width: 100%;
  position: relative;
  transition: var(--transitionStyleButton);

  /* &:hover {
    transform: scale(1.02);
    box-shadow: var(--boxShadowListingCard);
  } */
}

/* Firefox doesn't support image aspect ratio inside flexbox */
.aspectWrapper {
  padding-bottom: 66.6667%;
  /* 3:2 Aspect Ratio */
  background: var(--matterColorNegative);
  /* Loading BG color */
  border-top-left-radius: 40px;
  border-top-right-radius: 40px;
}

.rootForImage {
  /* Layout - image will take space defined by aspect ratio wrapper */
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  border-top-left-radius: 40px;
  border-top-right-radius: 40px;
}

.info {
  /* Layout */
  display: flex;
  flex-direction: column;
  text-align: center;
  padding: 16px;
}

.price {
  /* Layout */
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0;
}

.priceValue {
  /* Font */
  composes: h2 from global;
  color: var(--matterColorDark);

  /* Remove default margins from font */
  margin-top: 0;
  margin-bottom: 0;

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.perUnit {
  /* Font */
  composes: marketplaceTinyFontStyles from global;
  color: var(--matterColor);

  /* Remove default margins from font */
  margin-top: 0;
  margin-bottom: 0;

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.mainInfo {
  display: flex;
  flex-direction: column;
}

.title {
  /* Font */
  composes: h3 from global;
  color: var(--matterColorDark);

  /* Remove default margins from font */
  margin-top: 0;
  margin-bottom: 0;

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.authorInfo,
.location {
  /* Font */
  composes: marketplaceTinyFontStyles from global;
  color: var(--matterColor);

  /* Remove default margins from font */
  margin-top: 8px;
  margin-bottom: 8px;

  /* @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
  } */
}

.longWord {
  /* fallback option */
  word-break: break-all;
  /* use break-word if available */
  word-break: break-word;
  hyphens: auto;
}

.reviewWrapper {
  margin: 10px;
}

.rootRviewClass {
  display: inline-block;
}

.reviewStarClass {
  width: 18px;
  height: 18px;
  margin: 0 3px;
}

.locationWrapper {
  margin: 8px;

  display: flex;
  flex-direction: column;
  align-items: center;
}

.locationIcon {
  display: block;
  width: 20px;
  height: 20px;
}
