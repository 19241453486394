@import '../../styles/customMediaQueries.css';

.root {
  margin: var(--LandingPage_sectionMarginTop) 24px 51px 24px;

  /* Layout */
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 30px;

  @media (--viewportMedium) {
    /* max-width: 100%; */
    max-width: 840px;
    margin: var(--LandingPage_sectionMarginTopMedium) 24px 60px 24px;

    display: grid;
    grid-template-columns: 1fr 1fr;
  }

  @media (--viewportLarge) {
    /* max-width: 1128px; */
    padding: 0 36px 0 36px;
    margin: var(--LandingPage_sectionMarginTopLarge) auto 93px auto;
  }

  @media (--viewportXLarge) {
    /* max-width: 1056px; */
    padding: 0;
  }
}

.mobileTitle {
  composes: h1 from global;
  color: var(--matterColorThickDark);
  margin: 0 0 2px 0;
  text-align: center;

  @media (--viewportMedium) {
    display: none;
  }
}

.title {
  display: none;

  @media (--viewportMedium) {
    display: block;
    composes: h1 from global;
    color: var(--matterColorThickDark);
    padding: 30px 30px 45px;
    margin: 0;
    text-align: left;
  }
}

.feedbackBox {
  min-height: 430px;
  max-height: 550px;
  background-color: var(--matterColorLight);
  padding: 30px;
  border-radius: 40px;

  /* Layout*/
  display: flex;
  flex-direction: column;
  align-items: center;
}


.highlightedByColor {
  color: var(--matterColorLight);
}

.name {
  composes: h3 from global;
  color: var(--matterColorDark);
  font-size: 20px;
  font-weight: var(--fontWeightBold);
  margin: 20px 0 0;
  letter-spacing: 1px;
  text-transform: uppercase;
  text-align: center;
}

.tag {
  composes: h4 from global;
  color: #555273;
  font-size: 16px;
  font-weight: var(--fontWeightBold);
  margin: 20px 0 0;
  line-height: 19.2px;
  text-transform: capitalize;
  text-align: center;
}

.feedbackText {
  composes: p from global;
  font-size: 20px;
  line-height: 32px;
  font-weight: 300;
  letter-spacing: 0.5px;
  color: var(--matterColorThickDark);
  margin: 10px 0 0;
  padding: 10px 20px;
  text-align: center;
}

.imageWrapper {
  position: relative;
  width: 100%;
  max-height: 100px;
}

/* An image wrapper that defines the images aspect ratio */
.aspectWrapper {
  padding-bottom: calc(6 / 13 * 100%);
  /* 13:6 Aspect Ratio */

  @media (--viewportMedium) {
    padding-bottom: calc(2 / 3 * 100%);
    /* 3:2 Aspect Ratio */
  }
}

.imageMargin {
  position: absolute;
  inset: 0px;
  margin: 26px;
}

.tryEaselyImage {
  /* Layout - image will take space defined by aspect ratio wrapper */
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 60px;
  height: 60px;
  object-fit: cover;
  border-radius: 50%;
  margin: 0 auto;
}
