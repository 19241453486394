@import '../../styles/customMediaQueries.css';

.root {
  width: 100%;
  padding: 24px 24px 42px 24px;
  background-color: var(--matterColorLessDark);

  @media (--viewportMedium) {
    padding: 0 24px 33px 24px;
  }

  @media (--viewportLarge) {
    padding: 0 0 81px 0;
    border-top: none;
  }
}

/* Wrapper to limit the top border in desktop
 * view from the outermost paddings*/
.topBorderWrapper {
  @media (--viewportMedium) {
    padding-top: 41px;
  }

  @media (--viewportLarge) {
    padding-top: 73px;
  }
}

.content {
  display: flex;
  flex-direction: column;
  width: 100%;

  @media (--viewportMedium) {
    margin: 0 auto;
  }

  @media (--viewportLarge) {
    max-width: 1128px;
    padding: 0 36px;
  }

  @media (--viewportXLarge) {
    max-width: 1056px;
    padding: 0;
  }
}

/* Social Icons */
.socialIcons {
  margin: 0 0 24px;

  @media (--viewportMedium) {
    margin: 0 auto 24px;
  }
}

.icon {
  display: inline-block;
  width: 30px;
  height: 30px;
  margin-right: 24px;
  text-align: center;
}

/* Footer links */
.links {
  /* Layout */
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 30px;
  width: 100%;
  margin-bottom: 18px;
  cursor: pointer;

  @media (--viewportMedium) {
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 50px;
    margin-bottom: 0;
  }
}

/* Desktop: logo and organization stuff */
.organization {
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 30px;
  width: 100%;
  margin-top: 30px;

  @media (--viewportMedium) {
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 20px;
    margin-top: 40px;
  }
}

.logoLink {
  display: block;
  margin-bottom: 14px;
}

.logo {
  object-fit: contain;
  object-position: left center;
  width: 100%;
  height: 27px;
}

.link {
  /* Font */
  composes: h5 from global;
  /* font-size: 16px; */
  line-height: 20px;
  color: var(--matterColorLight);
  transition: var(--transitionStyleButton);

  margin-top: 0;
  margin-bottom: 12px;

  &:hover {
    color: var(--marketplaceColor);
    text-decoration: none;
  }

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.tosAndPrivacy {
  margin-top: 0;
  margin-bottom: 0;

  @media (--viewportMedium) {
    margin-bottom: 10px;
  }
}

.legalLink,
.privacy,
.terms {
  /* Font */
  composes: marketplaceTinyFontStyles from global;
  color: var(--matterColorAnti);
  transition: var(--transitionStyleButton);

  margin-top: 0;
  margin-bottom: 0;

  &:hover {
    color: var(--marketplaceColor);
    text-decoration: none;
  }

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
  }

}

.organizationCopyright {
  text-align: center;
}
