.root {}

.input {
  color: var(--matterColor) !important;
  border-top: none !important;
  border-left: none !important;
  border-right: none !important;
  border-bottom: 2px solid var(--successColor) !important;
  border-radius: 0px !important;
  cursor: pointer !important;
}

.active {
  color: var(--matterColor) !important;
  border-top: none !important;
  border-left: none !important;
  border-right: none !important;
  border-bottom: 2px solid var(--matterColor) !important;
  border-radius: 0px !important;
  box-shadow: none !important;
  cursor: pointer !important;
}

.valueHoler {
  padding-left: 0px !important;
}

.values {
  display: flex;
  align-items: center !important;
  justify-content: center !important;
  height: 25px;
}

.indicatorStyle {
  padding-right: 0px !important;
}

.indicatorStyle>svg {
  width: 25px !important;
  height: 25px !important;
  fill: var(--matterColor) !important;
}
