@import '../../styles/customMediaQueries.css';

.root {
  width: 100%;
  margin: var(--LandingPage_sectionMarginTop) 0 51px 0;
}

.sectionAssociationsTitle {
  composes: h1 from global;
  color: var(--matterColorLight);
  text-align: center;
  margin: 0 0 32px 0;

  @media (--viewportMedium) {
    margin: 0 0 47px 0;
  }
}

.highlightedByColor {
  color: var(--marketplaceColor);
}

.associationsLogoWrapper {
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 30px;

  @media (--viewportMedium) {
    grid-template-columns: repeat(6, 1fr);
  }
}

.imageWrapper {
  position: relative;
  width: 100%;
}

.iconWrapper {
  position: relative;
  width: 100%;
  height: 64px;
}

/* An image wrapper that defines the images aspect ratio */
.aspectWrapper {
  padding-bottom: calc(0.4 / 3 * 100%);
  /* 13:6 Aspect Ratio */

  @media (--viewportMedium) {
    padding-bottom: calc(1 / 3 * 100%);
    /* 3:2 Aspect Ratio */
  }
}

.imageMargin {
  position: absolute;
  inset: 0px;
  margin: 0px 80px;

  @media (--viewportMedium) {
    margin: 0 10px;
  }
}

.easelyImage {
  /* Layout - image will take space defined by aspect ratio wrapper */
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  object-fit: contain;
}

/* Animation for partners logos */
@keyframes lightSpeedIn {
  from {
    transform: translate3d(100%, 0, 0) skewX(-30deg);
    opacity: 0;
  }

  60% {
    transform: skewX(20deg);
    opacity: 1;
  }

  80% {
    transform: skewX(-5deg);
  }

  to {
    transform: translate3d(0, 0, 0);
  }
}

.lightSpeedIn {
  animation-name: lightSpeedIn;
  animation-duration: 1000ms;
  animation-timing-function: ease-out;
}
