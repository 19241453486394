@import '../../styles/customMediaQueries.css';

.label {
  composes: button marketplaceSearchFilterLabelFontStyles buttonText buttonBordersSecondary buttonColorsSecondary from global;
  width: auto;
  height: 35px;
  min-height: 35px;
  letter-spacing: 0.2px;
  font-weight: var(--fontWeightMedium);

  padding: var(--marketplaceButtonSmallDesktopPadding);
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;

  position: absolute;
  top: 0;
  right: 0;
  margin-right: 0px !important;

  @media (--viewportSmall) {
    height: auto;
    min-height: 0;
  }
}

.label:focus {
  outline: none;
  background-color: var(--matterColorLight);
  border-color: transparent;
  text-decoration: none;
  box-shadow: var(--boxShadowFilterButton);
}

.icon {
  fill: var(--matterColor);
}

.labelSelected {
  composes: button marketplaceSearchFilterLabelFontStyles buttonText buttonBorders buttonColors from global;
  width: auto;
  height: 35px;
  min-height: 35px;
  letter-spacing: 0.2px;
  font-weight: var(--fontWeightMedium);


  padding: var(--marketplaceButtonSmallDesktopPadding);
  border-radius: 4px;
  border: 1px solid var(--marketplaceColor);
  display: flex;
  align-items: center;
  justify-content: center;

  position: absolute;
  top: 0;
  right: 0;
  margin-right: 0px !important;

  @media (--viewportSmall) {
    height: auto;
    min-height: 0;
    font-weight: var(--fontWeightSemiBold);
  }
}

.iconSelected {
  fill: var(--matterColorLight);
}

.labelSelected:hover,
.labelSelected:focus {
  border: 1px solid var(--marketplaceColorDark);
}
