@import '../../styles/customMediaQueries.css';

.root {
  margin: var(--LandingPage_sectionMarginTop) 24px 51px 24px;

  @media (--viewportMedium) {
    max-width: 100%;
    margin: var(--LandingPage_sectionMarginTopMedium) 24px 60px 24px;
  }

  @media (--viewportLarge) {
    max-width: 1128px;
    padding: 0 36px 0 36px;
    margin: var(--LandingPage_sectionMarginTopLarge) auto 93px auto;
  }

  @media (--viewportXLarge) {
    max-width: 1056px;
    padding: 0;
  }
}

.sectionTryEaselyTitle {
  composes: h1 from global;
  color: var(--matterColorDark);
  text-align: center;
  margin: 0 0 32px 0;

  @media (--viewportMedium) {
    margin: 0 0 47px 0;
  }
}

.highlighted {
  color: var(--marketplaceColor);
}

.sectionTryEaselyContent {
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 40px;
  @media (--viewportLarge) {
    grid-template-columns: 1fr 1fr;
  }
}

.imageWrapper {
  position: relative;
  width: 100%;
}

.iconWrapper {
  position: relative;
  width: 100%;
  height: 64px;
  text-align: left;
}

/* An image wrapper that defines the images aspect ratio */
.aspectWrapper {
  padding-bottom: calc(6 / 13 * 100%);
  /* 13:6 Aspect Ratio */

  @media (--viewportMedium) {
    padding-bottom: calc(2 / 3 * 100%);
    /* 3:2 Aspect Ratio */
  }
}

.tryEaselyImage {
  /* Layout - image will take space defined by aspect ratio wrapper */
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.imageWrapper .tryEaselyImage {
  object-fit: cover;
}

.whyEaselyWrapper {
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 30px;

  @media (--viewportMedium) {
    grid-template-columns: 1fr 1fr;
  }
}

.whyEaselyTitle {
  composes: h3 from global;
  font-size: 24px;
  color: var(--marketplaceColor);
  margin: 12 0 0;
  text-align: center;

  @media (--viewportMedium) {
    font-size: 24px;
    line-height: 28px;
    letter-spacing: 1px;
  }

  @media (--viewportLargeWithPaddings) {
    text-align: left;
  }
}

.whyEaselySubtitle {
  composes: p from global;
  font-size: 14px;
  font-weight: 300;
  color: var(--matterColorDark);
  margin: 12 0 0;
  text-align: center;

  @media (--viewportMedium) {
    font-size: 14px;
    line-height: 18px;
    letter-spacing: 1px;
    text-align: left;
  }
}
