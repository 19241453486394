.flickingPanel {
  width: 70%;
  margin: 0 4px;
  border-left: 1px solid var(--matterColorAnti);
  border-right: 1px solid var(--matterColorAnti);
  border-bottom: 1px solid var(--matterColorAnti);
  border-radius: 15px;

  /* Layout */
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  cursor: grab;
}

.selected {
  border-left: 1px solid var(--successColor);
  border-right: 1px solid var(--successColor);
  border-bottom: 1px solid var(--successColor);
}

/* Firefox doesn't support image aspect ratio inside flexbox */
.aspectWrapper {
  position: relative;
  /* 3:2 Aspect Ratio */
  padding-bottom: 66.6667%;
  /* Loading BG color */
  background: var(--matterColorNegative);

  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
}

.rootForImage {
  /* Layout - image will take space defined by aspect ratio wrapper */
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
  padding: 0;
  margin: 0;
}

.title {
  composes: h3 from global;
  color: var(--matterColor);
  font-weight: var(--fontWeightSemiBold);
  padding: 0 10px;
  text-align: center;
}

.description {
  font-size: 14px;
  line-height: 18px;
  /* letter-spacing: 0.01px; */
  color: var(--matterColor);
  opacity: 0.8;
  padding: 0 10px;

}

.showLesMoreBtn {
  color: var(--marketplaceColor);
  cursor: pointer;
}

.sdgsWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  padding: 0 10px;
  margin: 0 0 20px;
}

.sdgsImages {
  margin: 4px;
}

.selectBtnWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 0 20px;
}
.btn {
  width: 50%;

}
.btnSlected {
  background-color: var(--marketplaceColorDark);
}

.arrow {
  width: 40px;
  height: 40px;
  background-color: var(--matterColorLight);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: var(--boxShadow);
}

.arrow::before,
.arrow::after {
  width: 0;
  height: 0;
}

.arrow>svg {
  width: 20px;
  height: 20px;
}

.arrow>svg path {
  fill: var(--marketplaceColor);
}
