/* Price Input */
.priceInput {
  flex-shrink: 0;
  width: 100%;
  margin-bottom: 24px;
}

.asterisk {
  composes: asterisk from global;
}
