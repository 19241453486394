@import '../../styles/customMediaQueries.css';

.root {
  padding-right: 0;

  @media (--viewportMedium) {
    padding-right: 60px;
  }
}

.inputWrapper {
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 20px;
  margin: 0 0 20px;

  @media (--viewportMedium) {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }
}

.input label,
.messageInput label {
  font-size: 16px;
  font-weight: var(--fontWightBold);
  line-height: 25.6px;
  color: var(--matterColorLight);
}

.messageInput {
  margin-bottom: 20px;
}

.contactUsFormBtn {
  max-width: 140px;
  min-height: auto;
  padding: 10px 30px;
  border-radius: 50px;
  text-transform: uppercase;
}

.asterisk {
  composes: asterisk from global;
}
