@import '../../styles/customMediaQueries.css';

.root {
  /* Layout */
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  /* Content of EditListingWizard should have smaller z-index than Topbar */
  z-index: 0;
}

.editListingContainer {
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 20px;
  margin: var(--LandingPage_sectionMarginTop) 24px 51px 24px;

  @media (--viewportMedium) {
    max-width: 700px;
    margin: var(--LandingPage_sectionMarginTopMedium) 24px 60px 24px;
  }
}

.tabsContainer {
  flex-grow: 1;
  /* Layout */
  width: 100%;
  height: 100%;

  display: flex;
  flex-direction: column;

  @media (--viewportLarge) {
    flex-direction: row;
    justify-content: center;
  }
}

.nav {
  /* Layout */
  display: flex;
  flex-direction: row;
  padding: 0;
  flex-shrink: 0;
  background-color: var(--matterColorLight);
  box-shadow: var(--boxShadowLight);
  border-top: 1px solid var(--matterColorNegative);
  overflow-x: scroll;

  @media (--viewportLarge) {
    padding: 128px 0 82px 36px;
    flex-direction: column;
    overflow-x: auto;
    background-color: var(--matterColorBright);
    box-shadow: none;
    border-top: none;
  }

  @media (--viewportLargeWithPaddings) {
    padding: 128px 0 82px calc((100% - 1056px) / 2);
  }
}

.tab {
  margin-left: 16px;
  white-space: nowrap;

  &:first-child {
    margin-left: 0;

    /* Padding added to tab so that it will be visible after call to scrollToTab */
    padding-left: 24px;
  }

  &:last-child {
    /* Padding added to tab so that it will be visible after call to scrollToTab */
    padding-right: 24px;
  }

  @media (--viewportLarge) {
    margin-left: 0;

    &:first-child {
      padding-left: 0;
    }

    &:last-child {
      padding-right: 0;
    }
  }
}

.panel {
  flex-grow: 1;

  @media (--viewportLarge) {
    padding: 88px 36px 82px 59px;
    border-left: 1px solid var(--matterColorNegative);
    background-color: var(--matterColorLight);
  }

  @media (--viewportLargeWithPaddings) {
    padding: 88px calc((100% - 1056px) / 2) 82px 59px;
  }
}

.payoutDetails {
  margin-bottom: 100px;

  @media (--viewportMedium) {
    margin-bottom: 0;
  }
}

.modalTitle {
  composes: marketplaceModalTitleStyles from global;
}

.modalPayoutDetailsWrapper {
  @media (--viewportMedium) {
    width: 604px;
    padding-top: 11px;
  }
}

.modalMessage {
  composes: marketplaceModalParagraphStyles from global;
}

/* toogle button */
.toggleBtnsWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 50px;
}

.toggleBtnsContainer {
  width: 100%;
  display: flex;
  align-items: center;

  background: var(--matterColorAnti);
  padding: 3px;
  border-radius: 4px;

  @media (--viewportSmall) {
    width: auto;
  }

  /* box-shadow: var(--boxShadowFilterButton); */
}

.btn {
  composes: button marketplaceSearchFilterLabelFontStyles buttonText buttonBordersSecondary buttonColorsSecondary from global;
  letter-spacing: 0.2px;
  font-size: 16px;
  font-weight: var(--fontWeightRegular);
  color: var(--matterColorLight);
  background-color: transparent;

  width: 50%;
  height: auto;
  min-height: 0;
  padding: 9px 24px;
  border: none;
  border-radius: 4px;

  &:focus,
  &:hover {
    outline: none;
    border: none;
    box-shadow: none;
  }

  @media (--viewportSmall) {
    width: auto;
    height: auto;
    min-height: 0;
  }
}

.btnSelected {
  composes: button marketplaceSearchFilterLabelFontStyles buttonText buttonBorders buttonColors from global;
  letter-spacing: 0.2px;
  font-size: 16px;
  font-weight: var(--fontWeightRegular);

  padding: 9px 24px;
  width: 50%;
  height: auto;
  min-height: 0;
  border-radius: 4px;
  border: none;
  box-shadow: none;

  &:hover,
  &:focus {
    border: none;
    outline: none;
    box-shadow: none;
  }

  @media (--viewportSmall) {
    width: auto;
    height: auto;
    min-height: 0;
  }
}
