@import '../../styles/customMediaQueries.css';

.root {
  margin: var(--LandingPage_sectionMarginTop) 24px 51px 24px;

  @media (--viewportMedium) {
    max-width: 100%;
    margin: var(--LandingPage_sectionMarginTopMedium) 24px 60px 24px;
  }

  @media (--viewportLarge) {
    max-width: 1128px;
    padding: 0 36px 0 36px;
    margin: var(--LandingPage_sectionMarginTopLarge) auto 93px auto;
  }

  @media (--viewportXLarge) {
    max-width: 1056px;
    padding: 0;
  }
}

.title {
  composes: h1 from global;
  color: var(--matterColorDark);
  margin: 0 0 32px 0;
  text-align: center;

  @media (--viewportMedium) {
    text-align: center;
    margin: 0 0 47px 0;
  }
}

.highlightedByColor {
  color: var(--marketplaceColor);
}

.subtitle {
  composes: h2 from global;
  color: var(--matterColorDark);
  margin: 20px 0 0;
  letter-spacing: 2.5px;
  text-transform: uppercase;
  text-align: center;

  @media (--viewportMedium) {
    text-align: left;
  }
}

.description {
  composes: p from global;
  font-size: 16px;
  line-height: 25.6px;
  font-weight: 300;
  letter-spacing: 0.5px;
  color: var(--matterColorThickDark);
  margin: 20px 0 0;
  text-align: center;

  @media (--viewportMedium) {
    text-align: left;
  }
}



.boxWrapper {
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 30px;

  @media (--viewportMedium) {
    grid-template-columns: 1fr 1fr;
  }
}

.box {
  width: 100%;
  background-color: var(--matterColorBrighter);
  padding: 28px 20px;
  border-radius: 40px;

  /* Layout */
  display: flex;
  flex-direction: column;
  align-items: center;
}

.iconWrapper {
  background-color: var(--matterColorLight);
  padding: 22px;
  color: #555273;
  border-radius: 10px;

}

.subtitle {
  composes: h3 from global;
  font-size: 20px;
  color: var(--matterColorThickDark);
  font-weight: var(--fontWeightSemiBold);
  letter-spacing: 1px;
  line-height: 24px;
  text-transform: uppercase;
  text-align: center;
  padding: 0 10px;
  margin: 34px 0 0;
}

.description {
  font-size: 16px;
  line-height: 25.6px;
  font-weight: 300;
  color: var(--matterColorDark);
  padding: 0 10px;
  margin-bottom: 30px;
  text-align: center;

  @media (--viewportMedium) {
    margin-bottom: 60px;
  }
}
