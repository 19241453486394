@import '../../styles/customMediaQueries.css';

.root {
  padding-right: 0;
}

.asterisk {
  composes: asterisk from global;
}

.password {
  margin: 24px 0;
}

.deleteSubmitBtn {
  background-color: var(--failColor);
  color: var(--matterColorLight);

  &:hover,
  &:focus {
    background-color: var(--failColorDark);
    color: var(--matterColorLight);
  }

  &:disabled {
    background-color: var(--matterColorNegative);
    color: var(--matterColorLighter);
  }
}

.error {
  /* Font */
  composes: h4 from global;
  color: var(--failColor);
  margin: 18px 0 12px;
}
